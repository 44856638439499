import React from 'react';
import PropTypes from 'prop-types';

import {
  Button, Row, Col,
} from "react-bootstrap";

const ButtonFilter = ({
  title, options, selected, onChange,
}) => {
  const toggleValue = (value: String) => {
    if (selected.indexOf(value) > -1) {
      onChange(value, false)
    } else {
      onChange(value, true)
    }
  }
  return (
    <Row className="sub-category">
      <Col md="2">{title}</Col>
      <Col md="10">
        {options.map((value, key) => 
          <Button type="button" key={key}
            onClick={() => toggleValue(value)}
            variant={selected.indexOf(value) > -1 ? 'info' : "secondary"}>
            {value}
          </Button>
        )}
      </Col>
    </Row>
  )
};

ButtonFilter.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
}

export default ButtonFilter;
