export const PUBLISHER_ICE = 'ice';
export const PUBLISHER_EIA = 'eia';
export const PUBLISHER_CME = 'cme';
export const PUBLISHER_PLATTS = 'platts';
export const PUBLISHER_CFTC = 'cftc';

export const publishers = [
    PUBLISHER_ICE, PUBLISHER_CME, PUBLISHER_EIA,
    PUBLISHER_CFTC, PUBLISHER_PLATTS,
]

export const subPublishers = {
    [PUBLISHER_ICE]: [
        "ifed", "ifeu", "iepa", "ngxc", "ifad", "ndex", "ifsg",
    ],
    [PUBLISHER_CME]: ['nymex'],
    [PUBLISHER_EIA]: [
        "coal", "pet", "intl", "pet_imports", "seds", "ng",
        "ieo", "emiss", "steo", "total", "nuc_status",
    ],
    [PUBLISHER_CFTC]: ['cot'],
    [PUBLISHER_PLATTS]: [
        "m2ms_power", "m2ms_natgas", "oil_other", "oil_assmts", "natgas",
        "metals", "futures", "power", "petchems", "agriculture", "coal",
        "shipping", "energytrans", "oil_assmts_disc", "agriculture_dis",
        "oil_other_disc", "shipping_disc",
    ]
}

export const dataTypes = ['fundamental_data', 'market_data'];
