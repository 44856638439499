import React from "react";

// react-bootstrap components
import {
  Button, Card,
  Container, Row, Col,
} from "react-bootstrap";

/*
type {
  parentId : string,
  Id : string,
  value : string
}
var sectorData = {
  id: 
}

id, name, parent
 */

function SectorClassification() {
  return (
    <>
      <Container fluid>
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12" sm="12">
                <h2 className="page-title">master static data management</h2>
                <h4 className="page-subtitle">sector classification</h4>
              </Col>
            </Row>
            <Row>
              <Col md="11" sm="12" className="ml-auto mr-auto">
                <Card className="master-card">
                  <Card.Header>
                    <Row>
                      <Col md="3">asset class <i className="fas fa-play" /></Col>
                      <Col md="3">sector <i className="fas fa-play" /></Col>
                      <Col md="3">sub-sector <i className="fas fa-play" /></Col>
                      <Col md="3">category <i className="fas fa-play" /></Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    Body
                    <Button type="button" variant="data">
                      add new line
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default SectorClassification;
