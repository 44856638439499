import React from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";
import { Button } from "react-bootstrap";

function FormDynamicItem({
  title, items, setItems
}) {
  const onChange = (idx: Number, key: String) => (val: String) => {
    setItems(items.map((item, itemIdx) => itemIdx === idx ? {...item, [key]: val} : item))
  }

  const onAddConversion = () => {
    setItems([...items, {name: '', value: ''}])
  }
  return (<>
      <div className="custom-property-wrap">
        <p>{title}</p>
        <div className="flex justify-between mb-2 conversion-wrap">
          <span>conversion list</span>
        </div>
        {items && items.map(({name, value}, key) => (
          <div className="flex justify-between mb-2 conversion-wrap" key={key}>
            <Input
              type="text"
              id={'conversion_1_'+key}
              value={name}
              onChange={(e) => onChange(key, 'name')(e.target.value)}
            />
            <Input
              type="text"
              id={'conversion_2_'+key}
              value={value}
              onChange={(e) => onChange(key, 'value')(e.target.value)}
            />
            <div>
              {key === (items.length-1) ? (
                <Button type="button" variant="data" onClick={() => onAddConversion()}>
                  add
                </Button>
              ) : ' '}
            </div>
          </div>
        ))}
      </div>
    </>);
}

FormDynamicItem.propTypes = {
  title: PropTypes.string,
  setItems: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
  })).isRequired,
};

export default FormDynamicItem;
