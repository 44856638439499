import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// react-bootstrap components
import {
  Button, Card
} from "react-bootstrap";

function MasterCard({
  cardtitle, btnname, btnlink, children
}) {
  const history = useHistory();

  return (
    <>
      <Card className="master-card">
        <Card.Header>{cardtitle}</Card.Header>
        <Card.Body>
          <p>{children}</p>
          <Button type="button" variant="data" onClick={() => history.push(btnlink)}>
            {btnname}
          </Button>
          <br />< br />
        </Card.Body>
      </Card>
    </>
  );
}

MasterCard.propTypes = {
  cardtitle: PropTypes.string,
  children: PropTypes.any,
  btnname: PropTypes.string,
  btnlink: PropTypes.string,
};

export default MasterCard;
