import { all } from 'redux-saga/effects';
import { authSagas } from 'containers/auth/saga';
import { staticSagas } from 'containers/static/saga';
import { companySagas } from 'containers/companies/saga';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...staticSagas,
    ...companySagas,
  ])
}
