import React from "react";
import PropTypes from "prop-types";

function FamilyItemSel({
  name, checked, idname, onChange,
}) {
  return (<>
      <li>
        <div className="custom-checkbox">
          <input 
            type="checkbox" 
            defaultChecked={checked} 
            id={idname}  
            className="custom-control-input"
            onChange={(e) => {onChange(e.target.checked)}} />
          <label htmlFor={idname} className="custom-control-label">
            {name}
          </label>
        </div>
      </li>      
    </>);
}

FamilyItemSel.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  idname: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default FamilyItemSel;
