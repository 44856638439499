import React, { useState } from "react";
// import { useParams } from 'react-router-dom';

import {
  Container, Row, Col, Button,
} from "reactstrap";

import API from 'helpers/api';


function UploadProducts() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFilePath, setUploadedFilePath] = useState('');

  const uploadProductsFile = () => {
    if (!selectedFile) {
      return
    }

    return API.uploadFileToS3(selectedFile).then(({key}) => {
      if (key) {
        setSelectedFile(null);
        setUploadedFilePath(key);
      } else {
        console.error('error')
      }
    }).catch(() => {
      alert('Failed to upload file.')
    })
  };

  return (
    <>
      <Container className="product-detail-wrap">
        <Row>
          <Col md="12" sm="12">
            <h2 className="page-title">upload new products only</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="custom-file">
              <input
                className="custom-file-input"
                id="new-products-file"
                // placeholder='Choose File'
                accept='application/excel .csv'
                lang="en" type="file"
                onChange={event => {setSelectedFile(event.target.files[0])}}
              />
              <label className="custom-file-label" htmlFor="new-products-file">
                {selectedFile ? selectedFile.name : "Choose File"}
              </label>
            </div>
            {uploadedFilePath && (
              <div>
                {uploadedFilePath}
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button disabled={!selectedFile} onClick={() => {uploadProductsFile()}}>upload</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UploadProducts;
