import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";

import { Auth } from 'aws-amplify';

import { AppContext } from './libs/contextLib';

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

const App = () => {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    const onLoad = async () => {
      try {
        const {
          signInUserSession: {
            accessToken: {
              payload = {'cognito:groups': null},
            },
          } = {
            accessToken: {
              payload: {'cognito:groups': null},
            },
          }
        } = await Auth.currentAuthenticatedUser();
        
        userHasAuthenticated(payload['cognito:groups'].indexOf('admin') > -1);
      } catch (e) {
        console.warn(e);
      } finally {
        setIsAuthenticating(false);
      }
    }
    onLoad();
  }, [userHasAuthenticated, setIsAuthenticating]);

  return (
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
      {!isAuthenticating && (
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      )}
    </AppContext.Provider>
  )
}

export default App;
