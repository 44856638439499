import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { Auth } from 'aws-amplify';

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Col,
} from "react-bootstrap";

import { useAppContext } from 'libs/contextLib';

function LoginPage() {
  // const [cardClasses, setCardClasses] = useState("card-hidden");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { userHasAuthenticated, isAuthenticated } = useAppContext();

  const history = useHistory();

  // useEffect(() => {
  //   setTimeout(function () {
  //     setCardClasses("");
  //   }, 1000);
  // }, []);

  const submitLogin = async () => {
    setIsLoading(true)
    return Auth.signIn(email, password).then(res => {
      const {
        signInUserSession: {
          accessToken: {
            payload = {'cognito:groups': null}
          },
        } = {
          accessToken: {
            payload: {'cognito:groups': null}
          }
        }
      } = res;
      // res.signInUserSession.accessToken.payload['cognito:groups']
      if (payload['cognito:groups'].indexOf('admin') > -1) {
        userHasAuthenticated(true);
      } else {
        setError('You are not authorized')
      }
    }).catch(err => {
      setError(err?.message);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/admin/static');
    }
  }, [isAuthenticated])

  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image.jpg").default}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <div className="form">
                <Card className={"card-login"}>
                  <Card.Header>
                    <h3 className="header text-center">welcome back</h3>
                  </Card.Header>
                  <Card.Body>
                    <Card.Body>
                      <Form.Group>
                        <Form.Control
                          placeholder="email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value.trim())}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          placeholder="password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card.Body>
                  <Card.Footer className="ml-auto mr-auto">
                    <p>{error}</p>
                    <Button disabled={isLoading} className="btn-wd" type="submit" onClick={submitLogin} variant="info">
                      sign in
                    </Button>
                  </Card.Footer>
                </Card>
              </div>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image.jpg").default +
              ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default LoginPage;
