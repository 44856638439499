import React, { useState } from "react";

// react-bootstrap components
import {
  Button, Card,
  Container, Row, Col,
} from "react-bootstrap";

const geoData = [
  {locationname: 'andorra', latitude: 123123, longitude: 123123, countrycode: 'ad'},
  {locationname: 'united arab emirates', latitude: 423123, longitude: 243123, countrycode: 'ae'},
  {locationname: 'afghanistan', latitude: 142423, longitude: 123123, countrycode: 'af'},
  {locationname: 'antigua and barbuda', latitude: 123123, longitude: 123123, countrycode: 'ag'},
  {locationname: 'anguiila', latitude: 12423, longitude: 123123, countrycode: 'ai'},
  {locationname: 'albania', latitude: 12313, longitude: 123123, countrycode: 'al'}
];

function Geographical() {
  const [geographicalData, setGeographicalData] = useState(geoData);
  return (
    <>
      <Container fluid>
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12" sm="12">
                <h2 className="page-title">master static data management</h2>
                <h4 className="page-subtitle">sector classification</h4>
              </Col>
            </Row>
            <Row>
              <Col md="11" sm="12" className="ml-auto mr-auto">
                <Card className="master-card">
                  <Card.Header>
                    <Row>
                      <Col md="3">location name</Col>
                      <Col md="3">latitude</Col>
                      <Col md="3">longitude</Col>
                      <Col md="3">country code</Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    {geographicalData.length > 0 && geographicalData.map((geo, idx) => (
                      <Row key={idx}>
                        <Col md="3" className={idx==0?'pt-3':''}>{geo.locationname}</Col>
                        <Col md="3" className={idx==0?'pt-3':''}>{geo.latitude}</Col>
                        <Col md="3" className={idx==0?'pt-3':''}>{geo.longitude}</Col>
                        <Col md="3" className={idx==0?'flex justify-around pt-3':'flex justify-around'}>
                          <div className="edit-btn-wrap">{geo.countrycode}</div>
                          <Button type="button" variant="edit">
                            edit
                          </Button>
                        </Col>
                      </Row>
                    ))}
                    <Row>
                      <Col className="text-right add-new-line">
                        <Button type="button" variant="data" onClick={() => setGeographicalData()}>
                          add new line
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default Geographical;
