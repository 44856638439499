import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Input
} from "reactstrap";

function FormInputItem({
  value, setValue, labelname, readOnly,
}) {
  const [param, setParam] = useState(value);
  const setProductAttr = (val) => {
    setParam(val);
    setValue(val);
  }
  return (<>
      <div className="flex justify-between align-center mb-2">
        <label htmlFor={labelname.trim()}>{labelname}</label>
        <Input
          placeholder={labelname}
          type="text"
          readOnly={readOnly}
          id={labelname.trim()}
          className={labelname === 'category' || labelname === 'location' ? 'thick-border': ''}
          value={param}
          onChange={(e) => setProductAttr(e.target.value)}
        />
      </div>
    </>);
}

FormInputItem.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  labelname: PropTypes.string,
  readOnly: PropTypes.bool,
};

FormInputItem.defaultProps = {
  value: '',
  readOnly: false,
}

export default FormInputItem;
