import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
// react-bootstrap components

import { useAppContext } from 'libs/contextLib';

// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
// import AdminFooter from "components/Footers/AdminFooter.js";

// dinamically create dashboard routes
import routes from "routes.js";

import image3 from "assets/img/sidebar.jpg";

function Admin() {
  const sidebarImage = image3;
  const history = useHistory();

  const { isAuthenticated } = useAppContext();

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/auth/login');
    }
  }, [isAuthenticated])

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <>
      <div className="wrapper admin-wrapper">
        <Sidebar
          routes={routes}
          image={sidebarImage}
        />
        <div className="main-panel">
          <AdminNavbar />
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
          {/* <AdminFooter /> */}
          <div
            className="close-layer"
            onClick={() =>
              document.documentElement.classList.toggle("nav-open")
            }
          />
        </div>
      </div>
    </>
  );
}

export default Admin;
