import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import {
  Row, Col, Button,
} from "reactstrap";
import {
  Modal,
} from "react-bootstrap";

import { publishers, subPublishers, dataTypes } from "libs/constants";

import FormInputItem from "components/molecules/static/FormInputItem";
import FormRadioItem from "components/molecules/static/FormRadioItem";
import FormDynamicItem from "components/molecules/static/FormDynamicItem";
import FormSelectItem from "components/molecules/static/FormSelectItem";

const ProductForm = ({
  data, modeCreate, onSubmit, onDelete,
}) => {
  const history = useHistory();

  const [product, setProduct] = useState(data);
  const [subPublisherOptions, setSubPublisherOptions] = useState([]);
  const [conversions, setConversions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getAttribute = (key: String) => {
    return product[key] || ''
  }

  const setAttribute = (key: String) => (value: any) => {
    if (key === 'publisher') {
      setSubPublisherOptions(subPublishers[value]);
    }
    setProduct({
      ...product,
      [key]: value,
    });
  }

  const getDerived = (key: String) => {
    const { properties: { derived = {} } = { derived: {}} } = product;
    return derived[key];
  }

  const setDerived = (key: String) => (value: any) => {
    const { properties = { derived: {}} } = product;
    const { derived = {} } = properties
    setProduct({
      ...product,
      properties: {
        ...properties,
        derived: {
          ...derived,
          [key]: value,
        }
      }
    })
  }

  const getColumnName = (key: String) => {
    const { properties: { column_name = {} } = { column_name: {}} } = product;
    return column_name[key];
  }

  const setColumnName = (key: String) => (value: any) => {
    const { properties = { column_name: {}} } = product;
    const { column_name = {} } = properties;
    setProduct({
      ...product,
      properties: {
        ...properties,
        column_name: {
          ...column_name,
          [key]: value,
        }
      }
    })
  }

  const getDescriptionName = (key: String) => {
    const { properties: { description_name = {} } = { description_name: {}} } = product;
    return description_name[key];
  }

  const setDescriptionName = (key: String) => (value: any) => {
    const { properties = { description_name: {}} } = product;
    const { description_name = {} } = properties;
    setProduct({
      ...product,
      properties: {
        ...properties,
        description_name: {
          ...description_name,
          [key]: value,
        }
      }
    })
  }

  useEffect(() => {
    const {
      publisher,
      properties: {
        units: {
          conversion_list = {}
        } = {
          conversion_list: {}
        }
      } = {
        units: {
          conversion_list: {}
        }
      }
    } = product || {
      properties: {
        units: {
          conversion_list: {}
        }
      }
    };
    if (publisher && subPublisherOptions.length === 0) {
      setSubPublisherOptions(subPublishers[publisher]);
      setConversions(Object.entries(conversion_list).map(([name, value]) => ({name, value})))
    } else if (modeCreate && subPublisherOptions.length === 0) {
      setSubPublisherOptions(subPublishers[publishers[0]]);
      setConversions(Object.entries(conversion_list).map(([name, value]) => ({name, value})))
    }
  }, [
    product, setSubPublisherOptions, subPublisherOptions, subPublishers,
  ]);

  const onClickSave = () => {
    const { properties = {} } = product;
    const { units = {} } = properties;
    onSubmit({
      ...product,
      properties: {
        ...properties,
        units: {
          ...units,
          conversion_list: conversions.reduce((cur, {name, value}) => ({...cur, [name]:value}), {})
        }
      }
    })
    .then(() => {
      history.push('/admin/static');
    }).catch(() => {
      alert('something went wrong')
    })
  }

  const onClickDelete = () => {
    if (typeof onDelete === 'function') {
      onDelete().then(() => {
        history.push('/admin/static');
      }).finally(() => {
        setShowDeleteModal(false);
      })
    }
  }

  const isValid = () => {
    const requiredAttributes = [
      'product_name', 'raw_native_id', 'publisher', 'subpublisher', 'data_type', 'data_type',
      'category', 'asset_class',
    ]
    return requiredAttributes.filter(item => !product[item]).length === 0
  }

  return (
    <>
      <Row>
        <Col md="11" sm="11" className="ml-auto mr-auto mt-4">
          {<Row className="flex justify-around family-wrap">
            <Col>
              <Row>
                <Col md="4">
                  <h5>product name information</h5>
                  <FormInputItem value={getAttribute('product_name')} setValue={setAttribute('product_name')} labelname="product name" />
                  <FormInputItem value={getAttribute('underlying_name')} setValue={setAttribute('underlying_name')} labelname="underlying name" />

                  <h5>product state information</h5>
                  <FormSelectItem
                    value={getAttribute('publisher')} options={publishers}
                    setValue={setAttribute('publisher')} labelname="publisher"
                    />
                  <FormSelectItem
                    value={getAttribute('subpublisher')} options={subPublisherOptions}
                    setValue={setAttribute('subpublisher')} labelname="sub publisher" />
                  <FormInputItem
                    value={getAttribute('raw_native_id')} readOnly={!modeCreate}
                    setValue={setAttribute('raw_native_id')} labelname="native id" />

                  <h5>augmented data information</h5>
                  <FormInputItem value={getAttribute('publisher_classification')} setValue={setAttribute('publisher_classification')} labelname="publisher classification" />
                  <FormSelectItem
                    value={getAttribute('data_type')} options={dataTypes}
                    setValue={setAttribute('data_type')} labelname="data type" />
                  <FormInputItem value={getAttribute('data_category')} setValue={setAttribute('data_category')} labelname="data category" />
                  <FormInputItem value={getAttribute('currency')} setValue={setAttribute('currency')} labelname="currency" />
                  <FormInputItem value={getAttribute('currentsize')} setValue={setAttribute('currentsize')} labelname="current size" />
                  <FormInputItem value={getAttribute('units')} setValue={setAttribute('units')} labelname="units" />
                </Col>
                <Col md="4">
                  <h5>sector classification information</h5>
                  <FormInputItem value={getAttribute('category')} setValue={setAttribute('category')} labelname="category" />
                  <FormInputItem value={getAttribute('asset_class')} setValue={setAttribute('asset_class')} labelname="asset class" />
                  <FormInputItem value={getAttribute('sector')} setValue={setAttribute('sector')} labelname="sector" />
                  <FormInputItem value={getAttribute('sub_sector')} setValue={setAttribute('sub_sector')} labelname="sub-sector" />

                  <h5>geographical information</h5>
                  <FormInputItem value={getAttribute('location')} setValue={setAttribute('location')} labelname="location" />
                  <FormInputItem value={getAttribute('latitude1')} setValue={setAttribute('latitude1')} labelname="latitude 1" />
                  <FormInputItem value={getAttribute('longitude1')} setValue={setAttribute('longitude1')} labelname="longitude 1" />
                  <FormInputItem value={getAttribute('unicode1')} setValue={setAttribute('unicode1')} labelname="UNLOCODE 1" />
                </Col>
                <Col md="4">
                  <h5>properties</h5>
                  <FormRadioItem title="derived" subtitle="is derived?" isTrue={getDerived('is_derived')}
                    desc="if true, target native_id" value={getDerived('target_native_id')} 
                    setTrueState={setDerived('is_derived')} setValue={setDerived('target_native_id')} />
                  <FormRadioItem title="column name" subtitle="is native column name?" isTrue={getColumnName('is_native_id_column_name')}
                    desc="if false, target column" value={getColumnName('alt_name')} 
                    setTrueState={setColumnName('is_native_id_column_name')} setValue={setColumnName('alt_name')} />
                  <FormRadioItem title="description name" subtitle="is description true?" isTrue={getDescriptionName('is_datasource_description')}
                    desc="if false, target description" value={getDescriptionName('alt_description')} 
                    setTrueState={setDescriptionName('is_datasource_description')} setValue={setDescriptionName('alt_description')} />

                  <FormDynamicItem title="units" items={conversions} setItems={setConversions} />
                </Col>
              </Row>
              <Row>
                <Col className="text-right">
                  {!modeCreate && <>
                    <Button type="button" variant="danger" onClick={() => {setShowDeleteModal(true)}}>
                      delete
                    </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                  </>}
                  <Button type="button" variant="info" disabled={!isValid()} onClick={onClickSave}>
                    save
                  </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button type="button" variant="data">
                    back to static data
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>}
        </Col>
      </Row>
      <Modal
        className="modal-mini modal-primary"
        onHide={() => setShowDeleteModal(false)}
        show={showDeleteModal}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-bulb-63"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>are you sure to delete this product?</p>
        </Modal.Body>
        <div className="modal-footer">
          <Button
            className="btn-simple"
            onClick={() => setShowDeleteModal(false)}
            variant="link"
          >
            cancel
          </Button>
          <Button
            className="btn-simple"
            onClick={onClickDelete}
            variant="link"
          >
            yes, delete
          </Button>
        </div>
      </Modal>
    </>
  )
}

ProductForm.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  modeCreate: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
}

ProductForm.defaultProps = {
  modeCreate: false,
}

export default ProductForm;
