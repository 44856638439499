import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

// react-bootstrap components
import {
  Button
} from "react-bootstrap";
import {
  Container, Row, Col,
  FormGroup, InputGroup,
  Input, InputGroupAddon,
  InputGroupText
} from "reactstrap";

import API from 'helpers/api';

import DataPublisher from "components/molecules/static/DataPublisher";

import {
  fetchFilterOptionsRequest,
  setSelectedFilterOptions,
} from './actions'
import ButtonFilter from "components/molecules/static/ButtonFilter";

function StaticData({
  fetchingFilterOptions, fetchingFilterOptionsError, availableFilterOprions,
  fetchFilterOptionsRequest, setSelectedFilterOptions, selectedFilterOptions,
}) {
  const history = useHistory();

  const [search, setSearch] = useState('');
  const [queryTimer, setQueryTimer] = useState(null);
  const [products, setProducts] = useState([]);
  const [totalProductCount, setTotalProductCount] = useState(0);

  const {
    asset_classes: assetClasses = [],
    sectors = [],
    data_categories: riskTypes = [],
    data_types: dataTypes = [],
    publisher_classifications: classifications = [],
    publishers: sources = [],
  } = availableFilterOprions;

  const fetchProducts = useCallback(() => {
    API.filterProducts(selectedFilterOptions).then(({products, total}) => {
      setProducts(products);
      setTotalProductCount(total);
    }).catch(err => {
      console.error(err);
    })
  }, [selectedFilterOptions, setProducts]);

  useEffect(() => {
    fetchFilterOptionsRequest();
  }, [fetchFilterOptionsRequest,]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts, selectedFilterOptions,]);

  console.log(totalProductCount)

  const onOptionSelectionUpdated = (identifier: String) => (option: String, checked: Boolean) => {
    const originalValue = selectedFilterOptions[identifier] || []
    const updated = {
      ...selectedFilterOptions,
      [identifier]: checked ? [...originalValue, option] : originalValue.filter(item => item !== option)
    }
    setSelectedFilterOptions(updated);
  }

  const resetFilters = () => {
    setSelectedFilterOptions({})
  }

  const updateSearchQuery = (value) => {
    setSelectedFilterOptions({
      ...selectedFilterOptions,
      query: value
    });
    setQueryTimer(null);
  }

  const onSearchQueryUpdated = (e) => {
    setSearch(e.target.value);
    if (queryTimer !== null) {
      clearTimeout(queryTimer);
      setQueryTimer(null);
    }

    setQueryTimer(setTimeout(() => {updateSearchQuery(e.target.value)}, 1000));
  }

  console.log(fetchingFilterOptions, fetchingFilterOptionsError);

  return (
    <>
      <Container fluid>
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12" sm="12">
                <h2 className="page-title">master static data management</h2>
                <h4 className="page-subtitle">static data</h4>
              </Col>
            </Row>
            <Row>
              <Col md="11" sm="11" className="ml-auto mr-auto mt-4">
                <Row className="flex justify-around family-wrap">
                  <Col md="3" sm="12">
                    <br /><br />
                    {/* filter section */}
                    <div className="publisher">
                      <Button type="button" variant="info" onClick={resetFilters}>
                        reset filters
                      </Button>
                      <DataPublisher
                        onOptionSelectionUpdated={onOptionSelectionUpdated('asset_classes')}
                        selected={selectedFilterOptions['asset_classes'] || []}
                        name={"asset class"} options={assetClasses.map(({value}) => value)} />
                      <DataPublisher
                        onOptionSelectionUpdated={onOptionSelectionUpdated('sectors')}
                        selected={selectedFilterOptions['sectors'] || []}
                        name={"sector"} options={sectors.map(({value}) => value)} />
                      {/* <DataPublisher
                        onOptionSelectionUpdated={onOptionSelectionUpdated}
                        name={"category"} options={categories.map(({value}) => value)} /> */}
                      <DataPublisher
                        onOptionSelectionUpdated={onOptionSelectionUpdated('data_types')}
                        selected={selectedFilterOptions['data_types'] || []}
                        name={"data type"} options={dataTypes.map(({value}) => value)} />
                      <DataPublisher
                        onOptionSelectionUpdated={onOptionSelectionUpdated('publisher_classifications')}
                        selected={selectedFilterOptions['publisher_classifications'] || []}
                        name={"security type"} options={classifications.map(({value}) => value)} />
                    </div>
                  </Col>
                  <Col md="9" sm="12">
                    {/* Search section */}
                    <FormGroup
                      className={"mb-3"}>
                      <InputGroup className="input-group-merge input-group-alternative narrow-search">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="narrow results by adding search criteria"
                          type="text"
                          value={search}
                          onChange={onSearchQueryUpdated}
                        />
                      </InputGroup>
                    </FormGroup>

                    <ButtonFilter
                      title='source' options={sources.map(({value}) => value)}
                      selected={selectedFilterOptions['publishers'] || []}
                      onChange={onOptionSelectionUpdated('publishers')}
                      />

                    <ButtonFilter
                      title='risk type' options={riskTypes.map(({value}) => value)}
                      selected={selectedFilterOptions['data_categories'] || []}
                      onChange={onOptionSelectionUpdated('data_categories')}
                      />

                    {/* family items */}
                    <Row>
                      <Col>
                        <Button type="button" variant="info" onClick={() => history.push('/admin/static/new')}>
                          add new product
                        </Button>
                      </Col>
                      <Col>
                        <Button type="button" variant="info" onClick={() => history.push('/admin/static/upload')}>
                          upload new products
                        </Button>
                      </Col>
                    </Row>

                    <div className="familiy-content">
                      {products.map(({
                        product_name: name,
                        _id: productId,
                        publisher,
                        raw_native_id: nativeId
                      }, key) =>
                        <Row key={key}>
                          <Col md="3">{nativeId}</Col>
                          <Col md="4">{name}</Col>
                          <Col md="2">{publisher}</Col>
                          <Col md="3">
                            <Button type="button" variant="info" onClick={() => history.push(`/admin/static/${productId}`)}>
                              edit
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Col>
                </Row>                
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

const mapStateToProps = state => ({
  fetchingFilterOptions: state.static.fetchingFilterOptions,
  fetchingFilterOptionsError: state.static.fetchingFilterOptionsError,
  availableFilterOprions: state.static.availableFilterOprions,
  selectedFilterOptions: state.static.selectedFilterOptions,
});

const mapDispatchToProps = dispatch => ({
  fetchFilterOptionsRequest: () => dispatch(fetchFilterOptionsRequest()),
  setSelectedFilterOptions: options => dispatch(setSelectedFilterOptions(options)),
});

StaticData.propTypes = {
  fetchingFilterOptions: PropTypes.bool.isRequired,
  fetchingFilterOptionsError: PropTypes.object,
  availableFilterOprions: PropTypes.shape({
    asset_classes: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })),
    sectors: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })),
    data_categories: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })),
    data_types: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })),
    publisher_classifications: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })),
    publishers: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })),
  }).isRequired,
  selectedFilterOptions: PropTypes.shape({
    asset_classes: PropTypes.arrayOf(PropTypes.string),
    sectors: PropTypes.arrayOf(PropTypes.string),
    data_types: PropTypes.arrayOf(PropTypes.string),
    publisher_classifications: PropTypes.arrayOf(PropTypes.string),
    publishers: PropTypes.arrayOf(PropTypes.string),
    data_categories: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  fetchFilterOptionsRequest: PropTypes.func.isRequired,
  setSelectedFilterOptions: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticData);
