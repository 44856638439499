import createReducer from 'redux/helpers/createReducer'

import {
  namespace,
} from './actions';

// =======================================
// Default state
// =======================================
const defaultState = {
}

// =======================================
// Reducers
// =======================================
const reducer = createReducer(namespace, defaultState, {
  // [setLoading.Type]: (state, action) => ({
  //   ...state,
  //   isLoading: action.payload,
  // }),
})

export default reducer
export { namespace }
