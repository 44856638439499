import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

/**
 * All reducers must be imported here
 */
import * as auth from 'containers/auth/reducer';
import * as staticData from 'containers/static/reducer';
import * as companies from 'containers/companies/reducer';


/**
 * current.name is the name defined in the reducer.js
 * current.default is the default export from the reducer file
 */
const allReducers = [
  auth,
  staticData,
  companies,
].reduce((all, current) => {
  return Object.assign({}, all, { [current.namespace]: current.default })
}, {})

const rootReducer = history =>
  combineReducers({
    ...allReducers,
    router: connectRouter(history),
  });

export default rootReducer;
