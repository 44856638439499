import React from "react";
// import { useHistory } from "react-router-dom";

// react-bootstrap components
import {
  // Button, Card,
  Container, Row, Col,
} from "react-bootstrap";

function DataSourceManagement() {
  // const history = useHistory();

  return (
    <>
      <Container fluid>
        <div className="section-image">
          {/* you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " */}
          <Container>
            <Row>
              <Col md="12" sm="12">
                <h2 className="page-title">data source management</h2>
              </Col>
            </Row>
            <Row>
              <Col md="11" sm="12" className="ml-auto mr-auto">
                
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default DataSourceManagement;
