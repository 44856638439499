import { Auth, API, Storage } from 'aws-amplify';

export default class RestAPI {
  static apiName = 'ethereal';
  /**
   * Return Authorized header
   * @returns {Promise}
   */
  static async __getAuthHeader() {
    try {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      return {
        Authorization: `Bearer ${token}`,
      }
    } catch (e) {
      console.error(e);
      if (window.location.pathname !== '/auth/login') {
        window.location.href = `/auth/login?redirect=${window.location.pathname}${window.location.search}${window.location.hash}`;
      }
    }
  }

  /**
   * Method to send GET request with authentication header
   * @param {String} endpoint 
   * @returns {Promise}
   */
  static async sendGetRequest(endpoint: String): Promise {
    const myInit = {
      headers: await RestAPI.__getAuthHeader(),
    };

    return API.get(this.apiName, endpoint, myInit);
  }

  /**
   * Send POST request with authentication header
   * @param {String} endpoint 
   * @param {Object} payload 
   */
  static async sendPostRequestWithoutAuthentication(endpoint: String, payload: Object = {}): Promise {
    const myInit = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: payload,
      response: false,
    };

    return API.post(this.apiName, endpoint, myInit);
  }

  /**
   * Send POST request with authentication header
   * @param {String} endpoint 
   * @param {Object} payload 
   */
  static async sendPostRequest(endpoint: String, payload: Object = {}): Promise {
    const myInit = {
      headers: {
        ...await RestAPI.__getAuthHeader(),
        'Content-Type': 'application/json',
      },
      body: payload,
      response: false,
    };

    return API.post(this.apiName, endpoint, myInit);
  }

  /**
   * Send POST request with authentication header
   * @param {String} endpoint 
   * @param {Object} payload 
   */
  static async sendPutRequest(endpoint: String, payload: Object = {}): Promise {
    const myInit = {
      headers: {
        ...await RestAPI.__getAuthHeader(),
        'Content-Type': 'application/json',
      },
      body: payload,
      response: false,
    };

    return API.put(this.apiName, endpoint, myInit);
  }

  /**
   * Send POST request with authentication header
   * @param {String} endpoint 
   * @param {Object} payload 
   */
  static async sendPatchRequest(endpoint: String, payload: Object = {}): Promise {
    const myInit = {
      headers: {
        ...await RestAPI.__getAuthHeader(),
        'Content-Type': 'application/json',
      },
      body: payload,
      response: false,
    };

    return API.patch(this.apiName, endpoint, myInit);
  }

  static async sendDelRequest(endpoint: String, params: Object = {}): Promise {
    const myInit = {
      headers: {
        ...await RestAPI.__getAuthHeader(),
        'Content-Type': 'application/json',
      },
      body: params
    };

    return API.del(this.apiName, endpoint, myInit);
  }

  /**
   * Get the current user ID
   * @returns {String}
   */
  static async __getUserID() {
    const idToken = (await Auth.currentSession()).getIdToken();
    return idToken.payload.sub;
  }

  /**
   * Configuration to use s3
   * @param {String} level 
   * @returns 
   */
  static async setS3Config(level) {
    return Storage.configure({
      bucket: process.env.REACT_APP_STORAGE_BUCKET,
      level: level,
      region: process.env.REACT_APP_REGION,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    });
  }

  static async uploadFileToS3(selectedFile: File, progressCallback: Function = () => { }) {
    const userId = await this.__getUserID();
    const defaultProgressCallback = () => { };

    return Storage.put(
      `static/${userId}/${selectedFile.name}`,
      selectedFile,
      {
        contentType: selectedFile.type,
        progressCallback: progressCallback || defaultProgressCallback,
      }
    )
  }

  /**
   * Reset Company user's password
   * @returns {Promise}
   */
  static async getAvailableOptions(queryType: String) {
    const path = `/products/filter-options?query_type=${queryType}`;
    return this.sendPostRequest(path, {});
  }

  /**
   * Filter products
   * @param {Object} options 
   * @returns {Promise}
   */
  static async filterProducts(options: Object, queryType: String = 'time_series') {
    const apiName = 'ethereal';
    const path = `/products/search?query_type=${queryType}`;
    const params = {
      headers: {
        ...await RestAPI.__getAuthHeader(),
        'Content-Type': 'application/json',
      },
      response: false,
      body: options,
    }

    return API.post(apiName, path, params)
  }

  /**
   * Create a new Product
   * @param {object} productData 
   * @returns 
   */
  static async createProduct(productData: Object) {
    const apiName = 'ethereal';
    const path = `/portal/static`;
    const params = {
      headers: {
        ...await RestAPI.__getAuthHeader(),
        'Content-Type': 'application/json',
      },
      response: false,
      body: productData,
    }

    return API.post(apiName, path, params)
  }

  /**
   * Retrieve a product by ID
   * @param {string} productId 
   * @returns {Object}
   */
  static async retrieveProduct(productId: String) {
    const apiName = 'ethereal';
    const path = `/products/${productId}`;
    const params = {
      headers: await RestAPI.__getAuthHeader(),
      response: false,
    }

    return API.get(apiName, path, params)
  }

  /**
   * Update a given Product
   * @param {String} productId
   * @param {Object} productData 
   * @returns 
   */
  static async updateProduct(productId: String, productData: Object) {
    const apiName = 'ethereal';
    const path = `/portal/static/${productId}`;
    const params = {
      headers: {
        ...await RestAPI.__getAuthHeader(),
        'Content-Type': 'application/json',
      },
      response: false,
      body: productData,
    }

    return API.put(apiName, path, params)
  }

  /**
   * Delete a given Product
   * @param {String} productId 
   * @returns 
   */
  static async deleteProduct(productId: String) {
    const apiName = 'ethereal';
    const path = `/portal/static/${productId}`;
    const params = {
      headers: {
        ...await RestAPI.__getAuthHeader(),
        'Content-Type': 'application/json',
      },
      response: false,
      body: {},
    }

    return API.del(apiName, path, params)
  }

  static async listCompanies(size: Number = 30, page: Number = 1) {
    return this.sendGetRequest(`/portal/companies?size=${size}&page=${page}`)
  }

  static async approveCompany(companyID: String) {
    return this.sendPostRequest(`/portal/companies/${companyID}/approve`)
  }
}
