import createReducer from 'redux/helpers/createReducer'

import {
  namespace,
  setSelectedFilterOptions, clearSelectedFilterOptions,
  fetchFilterOptionsRequest, fetchFilterOptionsSuccess, fetchFilterOptionsError,
} from './actions';

// =======================================
// Default state
// =======================================
const defaultState = {
  fetchingFilterOptions: false,
  fetchingFilterOptionsError: null,
  availableFilterOprions: {},
  selectedFilterOptions: {},
}

// =======================================
// Reducers
// =======================================
const reducer = createReducer(namespace, defaultState, {
  [fetchFilterOptionsRequest.Type]: (state) => ({
    ...state,
    fetchingFilterOptions: true,
    fetchingFilterOptionsError: null,
  }),
  [fetchFilterOptionsSuccess.Type]: (state, action: { payload: {options: Object}}) => ({
    ...state,
    fetchingFilterOptions: false,
    availableFilterOprions: action.payload.options,
  }),
  [fetchFilterOptionsError.Type]: (state, action: { payload: { error: any } }) => ({
    ...state,
    fetchingFilterOptions: false,
    fetchingFilterOptionsError: action.payload.error,
  }),
  [setSelectedFilterOptions.Type]: (state, action) => ({
    ...state,
    selectedFilterOptions: action.payload,
  }),
  [clearSelectedFilterOptions.Type]: (state) => ({
    ...state,
    selectedFilterOptions: {},
  }),
})

export default reducer
export { namespace }
