/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Auth
import LoginPage from "containers/auth/LoginPage";

// Static data
import MasterStaticData from "containers/static/MasterStaticData";
import StaticData from "containers/static/StaticData";
import ProductDetails from "containers/static/ProductDetails";

import Dashboard from "views/Dashboard.js";
import Buttons from "views/Components/Buttons.js";
import GridSystem from "views/Components/GridSystem.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlertPage.js";
import Notifications from "views/Components/Notifications.js";
import Icons from "views/Components/Icons.js";
import Typography from "views/Components/Typography.js";
// import RegularForms from "views/Forms/RegularForms.js";
// import ExtendedForms from "views/Forms/ExtendedForms.js";
// import ValidationForms from "views/Forms/ValidationForms.js";
// import Wizard from "views/Forms/Wizard/Wizard.js";
// import RegularTables from "views/Tables/RegularTables.js";
// import ExtendedTables from "views/Tables/ExtendedTables.js";
// import ReactTables from "views/Tables/ReactTables.js";
// import FullScreenMap from "views/Maps/FullScreenMap.js";
// import VectorMap from "views/Maps/VectorMap.js";
// import Charts from "views/Charts.js";
// import Calendar from "views/Calendar.js";
import UserPage from "views/Pages/UserPage.js";
// import RegisterPage from "views/Pages/RegisterPage.js";
// import LockScreenPage from "views/Pages/LockScreenPage.js";

import DataSourceManagement from "views/Pages/DataSourceManagement.js";
import SectorClassification from "views/SubPages/SectorClassification.js";
import ProductSlate from "components/molecules/static/ProductSlate";
import Geographical from "views/SubPages/Geographical.js";
import NewProduct from "containers/static/NewProduct";
import UploadProducts from "containers/static/UploadProducts";
import ListCompany from "containers/companies/ListCompany";

var routes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "dashboard",
    show: false,
    component: Dashboard,
  },
  {
    collapse: true,
    path: "/components",
    name: "components",
    state: "openComponents",
    show: false,
    views: [
      {
        path: "/buttons",
        layout: "/admin",
        name: "Buttons",
        mini: "B",
        component: Buttons,
      },
      {
        path: "/grid-system",
        layout: "/admin",
        name: "Grid System",
        mini: "GS",
        component: GridSystem,
      },
      {
        path: "/panels",
        layout: "/admin",
        name: "Panels",
        mini: "P",
        component: Panels,
      },
      {
        path: "/sweet-alert",
        layout: "/admin",
        name: "Sweet Alert",
        mini: "SA",
        component: SweetAlert,
      },
      {
        path: "/notifications",
        layout: "/admin",
        name: "Notifications",
        mini: "N",
        component: Notifications,
      },
      {
        path: "/icons",
        layout: "/admin",
        name: "Icons",
        mini: "I",
        component: Icons,
      },
      {
        path: "/typography",
        layout: "/admin",
        name: "Typography",
        mini: "T",
        component: Typography,
      },
    ],
  },
  {
    path: "/companies",
    layout: "/admin",
    name: "companies",
    component: ListCompany,
  },
  {
    path: "/user",
    layout: "/admin",
    name: "user management",
    component: UserPage,
  },
  {
    path: "/master",
    layout: "/admin",
    name: "static data management",
    component: MasterStaticData,
  },
  {
    path: "/source",
    layout: "/admin",
    name: "data source management",
    component: DataSourceManagement,
  },
  {
    path: "/login",
    layout: "/auth",
    name: "login",
    show: false,
    component: LoginPage,
  },
  {
    path: "/sector",
    layout: "/admin",
    name: "sector classification",
    show: false,
    component: SectorClassification,
  },
  {
    path: "/slate",
    layout: "/admin",
    name: "product slate",
    show: false,
    component: ProductSlate,
  },
  {
    path: "/geograph",
    layout: "/admin",
    name: "geographical classification",
    show: false,
    component: Geographical,
  },
  {
    path: "/static/new",
    layout: "/admin",
    name: "new product",
    show: false,
    component: NewProduct,
  },
  {
    path: "/static/upload",
    layout: "/admin",
    name: "upload products",
    show: false,
    component: UploadProducts,
  },
  {
    path: "/static/:productId",
    layout: "/admin",
    name: "product details",
    show: false,
    component: ProductDetails,
  },
  {
    path: "/static",
    layout: "/admin",
    name: "data universe",
    show: false,
    component: StaticData,
  },
];
export default routes;
