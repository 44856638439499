import createReducer from 'redux/helpers/createReducer'

import {
  namespace,
} from './actions';

// =======================================
// Default state
// =======================================
const defaultState = {
}

// =======================================
// Reducers
// =======================================
const reducer = createReducer(namespace, defaultState, {
})

export default reducer
export { namespace }
