import React from "react";
import { Link, useHistory } from "react-router-dom";

import { Auth } from 'aws-amplify';

// react-bootstrap components
import {
  Navbar,
  Nav,
  Container,
} from "react-bootstrap";

import { useAppContext } from 'libs/contextLib';

function AdminNavbar() {
  const history = useHistory();

  const { userHasAuthenticated } = useAppContext();

  const logout = () => {
    Auth.signOut().then(() => {
      userHasAuthenticated(false);
      history.push('/auth/login');
    });
  }
  return (
    <>
      <Navbar expand="lg" className="admin-nav">
        <Container fluid>            
          <Nav navbar className="ml-auto">
            <Nav.Item className="ml-1">
              <Nav.Link to="/admin/dashboard" as={Link}>
                home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="ml-1">
              <Nav.Link to="/admin/dashboard" as={Link}>
                profile
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="ml-1">
              <Nav.Link to="/admin/dashboard" as={Link}>
                web app
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="ml-1">
              <Nav.Link to="#" as={Link} onClick={logout}>
                logout
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
