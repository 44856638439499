import React from "react";
// import { useParams } from 'react-router-dom';

import {
  Container, Row, Col
} from "reactstrap";

import API from 'helpers/api';

import ProductForm from "components/molecules/static/ProductForm";
import { publishers } from "libs/constants";
import { subPublishers } from "libs/constants";
import { dataTypes } from "libs/constants";

function NewProduct() {
  const createProduct = (data) => {
    return API.createProduct(data).then(({status}) => {
      if (status) {
        console.log(status)
      } else {
        console.log('error')
      }
    })
  };

  const initialProduct = {
    publisher: publishers[0],
    subpublisher: subPublishers[publishers[0]][0],
    data_type: dataTypes[0],
    properties: {
      column_name: {
        alt_name: "",
        is_native_id_column_name: false
      },
      derived: {
        is_derived: false,
        target_native_id: ""
      },
      description_name: {
        alt_description: "",
        is_datasource_description: false,
      }
    }
  }

  return (
    <>
      <Container className="product-detail-wrap">
        <Row>
          <Col md="12" sm="12">
            <h2 className="page-title">master static data management</h2>
            <h4 className="page-subtitle">product detail</h4>
          </Col>
        </Row>
        <ProductForm modeCreate={true} data={initialProduct} onSubmit={createProduct} />
      </Container>
    </>
  );
}

export default NewProduct;
