import React, { useState } from "react";

// react-bootstrap components
import {
  Button, Card,
  Form, Container,
  Row, Col,
} from "react-bootstrap";

function UserPage() {
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [city, setCity] = useState('');
  const [statename, setStateName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [birthday, setBirthday] = useState('');
  const [streetaddress, setStreetAddress] = useState('');
  const [password, setPassword] = useState('');
  return (
    <>
      <Container fluid>
        <div className="section-image">
          {/* you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " */}
          <Container>
            <Row>
              <Col md="12" sm="12">
                <h2 className="page-title">admin profile</h2>
              </Col>
            </Row>
            <Row>
              <Col md="8" sm="10" className="ml-auto mr-auto admin-profile">
                <Form action="" className="form" method="">
                  <Card className="px-2">
                    <Card.Body>
                      <Row>
                        <Col className="px-1 pl-3" md="2">
                          <label htmlFor="firstname">first name</label>
                        </Col>
                        <Col className="pr-3 pl-0" md="4">
                          <Form.Group>
                            <Form.Control
                              id="firstname"
                              value={firstname}
                              placeholder=""
                              type="text"
                              onChange={(e) => setFirstName(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="px-1" md="2">
                          <label htmlFor="city">city</label>
                        </Col>
                        <Col className="pr-3 pl-0" md="4">
                        <Form.Group>
                            <Form.Control
                              id="city"
                              value={city}
                              placeholder=""
                              type="text"
                              onChange={(e) => setCity(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="px-1 pl-3" md="2">
                          <label htmlFor="lastname">last name</label>
                        </Col>
                        <Col className="pr-3 pl-0" md="4">
                          <Form.Group>
                            <Form.Control
                              id="lastname"
                              value={lastname}
                              placeholder=""
                              type="text"
                              onChange={(e) => setLastName(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="px-1" md="2">
                          <label htmlFor="statename">state</label>
                        </Col>
                        <Col className="pr-3 pl-0" md="4">
                        <Form.Group>
                            <Form.Control
                              id="statename"
                              value={statename}
                              placeholder=""
                              type="text"
                              onChange={(e) => setStateName(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="px-1 pl-3" md="2">
                          <label htmlFor="email">email address</label>
                        </Col>
                        <Col className="pr-3 pl-0" md="4">
                          <Form.Group>
                            <Form.Control
                              id="email"
                              value={email}
                              placeholder=""
                              type="text"
                              onChange={(e) => setEmail(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="px-1" md="2">
                          <label htmlFor="country">country</label>
                        </Col>
                        <Col className="pr-3 pl-0" md="4">
                        <Form.Group>
                            <Form.Control
                              id="country"
                              value={country}
                              placeholder=""
                              type="text"
                              onChange={(e) => setCountry(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="px-1 pl-3" md="2">
                          <label htmlFor="phonenumber">phone number</label>
                        </Col>
                        <Col className="pr-3 pl-0" md="4">
                          <Form.Group>
                            <Form.Control
                              id="phonenumber"
                              value={phonenumber}
                              placeholder=""
                              type="text"
                              onChange={(e) => setPhoneNumber(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="px-1" md="2">
                          <label htmlFor="birthday">birthday</label>
                        </Col>
                        <Col className="pr-3 pl-0" md="4">
                        <Form.Group>
                            <Form.Control
                              id="birthday"
                              value={birthday}
                              placeholder=""
                              type="text"
                              onChange={(e) => setBirthday(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="px-1 pl-3" md="2">
                          <label htmlFor="streetaddress">street address</label>
                        </Col>
                        <Col className="pr-3 pl-0" md="4">
                          <Form.Group>
                            <Form.Control
                              id="streetaddress"
                              value={streetaddress}
                              placeholder=""
                              type="text"
                              onChange={(e) => setStreetAddress(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="px-1" md="2">
                          <label htmlFor="password">password</label>
                        </Col>
                        <Col className="pr-3 pl-0" md="4">
                        <Form.Group>
                            <Form.Control
                              id="password"
                              value={password}
                              placeholder=""
                              type="text"
                              onChange={(e) => setPassword(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-0 mt-4">
                        <Col md="12" className="text-center">
                          <Button type="button" variant="info">
                            edit
                          </Button>&nbsp;&nbsp;
                          <Button type="button" variant="info">
                            save
                          </Button>
                        </Col>
                      </Row>
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default UserPage;
