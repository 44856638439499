import React from "react";

// react-bootstrap components
import {
  Container, Row, Col,
} from "react-bootstrap";
import MasterCard from "components/molecules/static/MasterCard";

function ProductSlate() {
  return (
    <>
      <Container fluid>
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12" sm="12">
                <h2 className="page-title">master static data management</h2>
                <h4 className="page-subtitle">product slate - dashboard</h4>
              </Col>
            </Row>
            <Row>
              <Col md="11" sm="11" className="ml-auto mr-auto mt-4">
                <Row className="flex justify-around">
                  <Col md="4" sm="12">
                    <MasterCard cardtitle="cme datamine" btnname="time to mine" btnlink="/admin/slate">
                      view cme product slate
                    </MasterCard>
                  </Col>
                  <Col md="4" sm="12">
                    <MasterCard cardtitle="ice downloads2" btnname="ice baby" btnlink="/admin/slate">
                      view ice product slate
                    </MasterCard>
                  </Col>
                </Row>                
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default ProductSlate;
