import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// react-bootstrap components
import {
  Collapse,
  NavLink,
} from "reactstrap";

import FamilyItemSel from "./FamilyItemSel";

function DataPublisher({
  name, options, selected, onOptionSelectionUpdated,
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (<>
    <div className="family-item">
      <NavLink onClick={() => setIsOpen(!isOpen)} role="tab">
        {name}
        <i className={classnames("fas fa-play", {rotate: isOpen})}></i>
      </NavLink>
    </div>
    <Collapse isOpen={isOpen}>
      <ul>
        {options.map((item, idx) => (
          <FamilyItemSel
            key={idx} name={item} onChange={(value) => onOptionSelectionUpdated(item, value)}
            checked={selected.indexOf(item) > -1} idname={`${name}-${item}`}
            />
        ))}
      </ul>
    </Collapse>
    </>);
}

DataPublisher.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  onOptionSelectionUpdated: PropTypes.func.isRequired,
};

DataPublisher.defaultProps = {
  selected: [],
}

export default DataPublisher;
