import createAction from 'redux/helpers/createAction';

export const namespace = 'static';

export const setLoading = createAction(namespace, 'SET_LOADING');
export const setError = createAction(namespace, 'SET_ERROR');

export const setSelectedFilterOptions = createAction(namespace, 'SET_SELECTED_FILTER_OPTIONS');
export const clearSelectedFilterOptions = createAction(namespace, 'CLEAR_SELECTED_FILTER_OPTIONS');

export const fetchFilterOptionsRequest = createAction(namespace, 'FETCH_FILTER_OPTIONS_REQUEST');
export const fetchFilterOptionsSuccess = createAction(namespace, 'FETCH_FILTER_OPTIONS_SUCCESS');
export const fetchFilterOptionsError = createAction(namespace, 'FETCH_FILTER_OPTIONS_ERROR');
