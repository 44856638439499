import React, { useCallback, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import {
  Container, Row, Col
} from "reactstrap";

import API from 'helpers/api';

import ProductForm from "components/molecules/static/ProductForm";

function ProductDetails() {
  const { productId } = useParams();
  const [fetchingProduct, setFetchingProfile] = useState(false);
  const [product, setProduct] = useState({});

  const fetchProduct = useCallback(() => {
    setFetchingProfile(true);
    return API.retrieveProduct(productId).then(({product, status}) => {
      if (status) {
        setProduct(product);
      } else {
        console.log('error')
      }
    }).finally(() => {
      setFetchingProfile(false);
    })
  }, [setFetchingProfile, setProduct]);

  const updateProduct = (productData) => {
    return API.updateProduct(productId, productData).then(({status}) => {
      console.log(status);
    }).catch((e) => {
      console.error(e)
    })
  }

  const deleteProduct = () => {
    return API.deleteProduct(productId).then(({status}) => {
      console.log(status);
    }).catch((e) => {
      console.error(e);
      alert('something went wrong!');
    })
  }

  useEffect(() => {
    if (productId) {
      fetchProduct();
    }
  }, [productId, fetchProduct]);

  return (
    <>
      <Container className="product-detail-wrap">
        <Row>
          <Col md="12" sm="12">
            <h2 className="page-title">master static data management</h2>
            <h4 className="page-subtitle">product detail</h4>
          </Col>
        </Row>
        {!fetchingProduct && <ProductForm data={product} onSubmit={updateProduct} onDelete={deleteProduct} />}
      </Container>
    </>
  );
}

export default ProductDetails;
