import React from "react";
import PropTypes from "prop-types";

// import {
//   Input
// } from "reactstrap";

function FormSelectItem({
  value, setValue, labelname, readOnly,
  options,
}) {
  return (<>
      <div className="flex justify-between align-center mb-2">
        <label htmlFor={labelname.trim()}>{labelname}</label>
        <select
          readOnly={readOnly}
          id={labelname.trim()}
          className={labelname === 'category' || labelname === 'location' ? 'thick-border': ''}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          {options.map((item, idx) => (<option value={item} key={idx}>{item}</option>))}
        </select>
      </div>
    </>);
}

FormSelectItem.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  labelname: PropTypes.string,
  readOnly: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

FormSelectItem.defaultProps = {
  value: '',
  readOnly: false,
}

export default FormSelectItem;
