import React, { useEffect, useState } from 'react';

import RestAPI from 'helpers/api';
import { Container } from 'react-bootstrap';


const ListCompany = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    RestAPI.listCompanies().then(({ companies }) => {
      setCompanies(companies);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      setIsLoading(false);
    })
  }, [])

  return (
    <>
      <Container className='section-companies'>
        <h2>Companies</h2>
        <table className='responsible table'>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Member since</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && <tr><td colSpan={4}>Loading...</td></tr>}
            {companies.map(({ name, member_since: memberSince, status, bucket_name: bucketName }, idx) => (
              <tr key={idx}>
                <td title={bucketName}>{idx + 1}</td>
                <td>{name}</td>
                <td>{memberSince}</td>
                <td>{status}</td>
                <td>
                  {status === 'pending' ? (
                    <button className='btn btn-xs btn-primary'>Approve</button>
                  ) : (
                    <button className='btn btn-xs btn-danger'>Delete</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Container>
    </>
  )
}

export default ListCompany;
