import { call, put, takeEvery } from 'redux-saga/effects';

import API from 'helpers/api';

import {
  fetchFilterOptionsRequest, fetchFilterOptionsSuccess, fetchFilterOptionsError,
} from './actions';

function* fetchFilterOptions() {
  try {
    const { status, options, msg } = yield call(API.getAvailableOptions);
    if (status) {
      yield put(fetchFilterOptionsSuccess({options}));
    } else {
      yield put(fetchFilterOptionsError({error: msg}));
    }
  } catch (e) {
    yield put(fetchFilterOptionsError({error: e}));
  }
}

export const staticSagas = [
  takeEvery(fetchFilterOptionsRequest.Type, fetchFilterOptions),
];
