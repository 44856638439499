import React from "react";
import PropTypes from "prop-types";

import {
  Input
} from "reactstrap";

function FormRadioItem({
  title, subtitle, desc, value, setValue, isTrue, setTrueState
}) {
  return (<>
      <div className="custom-property-wrap">
        <p>{title}</p>

        <div className="flex justify-between align-center mb-2">
          <label htmlFor="productname">{subtitle}</label>
          <div className="custom-checkbox">
            <input 
              type="radio" 
              id={title+'true'}
              name={title}
              checked={isTrue}
              className="custom-control-input"
              onChange={(e) => {setTrueState(e.target.checked)}} />
            <label htmlFor={title+'true'} className="custom-control-label">
              true
            </label>
          </div>
          <div className="custom-checkbox">
            <input 
              type="radio" 
              name={title}
              checked={!isTrue}
              id={title+'false'}  
              className="custom-control-input"
              onChange={(e) => {setTrueState(!e.target.checked)}} />
            <label htmlFor={title+'false'} className="custom-control-label">
              false
            </label>
          </div>
        </div>
        <div className="flex justify-between align-center mb-2">
          <label htmlFor={title+'desc'}>{desc}</label>
          <Input
            placeholder={subtitle}
            type="text"
            id={title+'desc'}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
    </>);
}

FormRadioItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isTrue: PropTypes.bool,
  desc: PropTypes.string,
  value: PropTypes.string,
  setTrueState: PropTypes.func,
  setValue: PropTypes.func
};

export default FormRadioItem;
