import React from "react";

// react-bootstrap components
import {
  Container, Row, Col,
} from "react-bootstrap";
import MasterCard from "components/molecules/static/MasterCard";

function MasterStaticData() {

  return (
    <>
      <Container fluid>
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12" sm="12">
                <h2 className="page-title">master static data management</h2>
              </Col>
            </Row>
            <Row>
              <Col md="11" sm="11" className="ml-auto mr-auto mt-4">
                <Row>
                  <Col md="4" sm="12" className="ml-auto mr-auto">
                    <MasterCard cardtitle="master static data" btnname="master this!" btnlink="/admin/static">
                      view and edit ethereal risk&apos;s <br />master static data
                    </MasterCard>
                  </Col>
                </Row>
                <Row>
                  <Col md="4" sm="12">
                    <MasterCard cardtitle="sector classification" btnname="let's go" btnlink="/admin/sector">
                      view and amend existing sector classification within ethereal risk system
                    </MasterCard>
                  </Col>
                  <Col md="4" sm="12">
                    <MasterCard cardtitle="geographical classification" btnname="time to dance" btnlink="/admin/geograph">
                      view and amend ethereal risk&apos;s <br />geographical classification list
                    </MasterCard>
                  </Col>
                  <Col md="4" sm="12">
                    <MasterCard cardtitle="product slate" btnname="party on" btnlink="/admin/slate">
                      view and amend ethereal risk&apos;s <br />product slate by sub-publisher
                    </MasterCard>
                  </Col>
                </Row>                
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default MasterStaticData;
